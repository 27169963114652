import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusAddressService, AddressServiceEdges } from './address-services.interface';

export default (): AddressServiceEdges[] => {
    const { allDirectusAddressService } = useStaticQuery<AllDirectusAddressService>(graphql`
    query {
      allDirectusAddressService {
        edges {
          node {
            directusId
            sort
            title
            slug
            content
            image {
              data {
                full_url
              }
            }
            quantity_toggle
            london_service_id
            london_service_price {
              value
            }
            london_service_discounted_price {
              value
            }
            london_address
            london_tooltip
            glasgow_service_id
            glasgow_service_price {
              value
            }
            glasgow_service_discounted_price {
              value
            }
            glasgow_address
            glasgow_tooltip
          }
        }
      }
    }
  `);

    allDirectusAddressService.edges.map(({ node }) => {
        node.london = {
            id: node.london_service_id,
            address: node.london_address,
            tooltip: node.london_tooltip,
            price: node.london_service_price,
            discounted_price: node.london_service_discounted_price,
            quantity_toggle: node.quantity_toggle,
        };
        node.glasgow = {
            id: node.glasgow_service_id,
            address: node.glasgow_address,
            tooltip: node.glasgow_tooltip,
            price: node.glasgow_service_price,
            discounted_price: node.glasgow_service_discounted_price,
            quantity_toggle: node.quantity_toggle,
        };

        return null;
    });

    return allDirectusAddressService.edges;
};
