import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import Modal from 'components/common/modal/Modal';
import PageSections from 'components/directus/page-sections/PageSections';
import addressServicesHook from 'hooks/address-services/address-services.hook';
import pageTitleHook from 'hooks/page-title/page-title.hook';
import packagesHook from 'hooks/packages/packages.hook';
import { getCheckoutPath } from 'utils/checkout-path';
import { cookieOptions } from 'utils/cookie';
import { getFieldBySlug } from 'utils/directus';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome';
import { url } from 'utils/url';
import { ProductProps } from './AddressServices.interface';

import s from './AddressServices.scss';

export default (): JSX.Element => {
    const addressServices = addressServicesHook();
    const [ packageSlug, setPackageSlug ] = useState<string>('');
    const [ cart, setCart ] = useState([]);
    const [ cookies, setCookie ] = useCookies();
    const [ londonQuantity, setLondonQuantity ] = useState<number>(1);
    const [ glasgowQuantity, setGlasgowQuantity ] = useState<number>(1);
    const companyName = cookies[ 'company-name' ];
    const checkoutPath = getCheckoutPath(packageSlug, packagesHook());
    const { node } = getFieldBySlug('address-services', pageTitleHook());

    useEffect(() => {
        if (companyName === undefined) navigate('/compare-packages');
    }, []);

    useEffect(() => {
        if (typeof window !== undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            const packageSlug = urlParams.get('p');
            setPackageSlug(packageSlug);
        }
    }, []);

    const isInCart = (key: string, value: string | number) => cart.findIndex(item => item[ key ] === value) > -1;

    const addToCart = (id: number, quantity: number, type: string) => {
        if (!isInCart('id', id)) {
            setCart([ ...cart, { id, quantity, type } ]);
        } else {
            const items = [ ...cart ];
            const itemIndex = cart.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                items[ itemIndex ] = {
                    ...items[ itemIndex ],
                    quantity,
                };
            }
            setCart(items);
        }
    };

    const removeFromCart = (id: number) => {
        const items = [ ...cart ];
        const itemIndex = cart.findIndex(item => item.id === id);
        if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
            setCart(items);
        }
    };

    const proceedCheckout = () => {
        if (typeof window !== 'undefined' && window.location && window.document) {
            setCookie('items', JSON.stringify(cart), cookieOptions());
            if (checkoutPath) {
                window.location.href = url.account(`${checkoutPath}?name=${companyName}&gle=namecheck`);
            } else {
                navigate('/');
            }
        }
    };

    const Product = (props: ProductProps) => {
        const { id, location, tooltip, address, price, discounted_price, quantity_toggle, type } = props;
        const activeClass = isInCart('id', id) ? s.addressServices__product___active : '';
        const hiddenClass = isInCart('type', type) && !isInCart('id', id) && type !== 'service-address'
            ? s.addressServices__product___hidden : '';
        const quantity = location === 'London' ? londonQuantity : glasgowQuantity;
        const setQuantity = (quantity: number) =>
            location === 'London' ? setLondonQuantity(quantity) : setGlasgowQuantity(quantity);

        return (
            <div className={`${s.addressServices__product} ${activeClass} ${hiddenClass}`}>
                <div className={s.addressServices__productLeft}>
                    <div className={s.addressServices__productAddress}>
                        <h4 className={s.addressServices__productLocation}>{location}:</h4>
                        <Markdown className={s.addressServices__productAddress} source={address} container />
                    </div>
                    <Modal trigger={<FontAwesomeIcon icon={getSolidFontAwesomeIcon('faInfoCircle')} />}>
                        <Markdown source={tooltip} container />
                    </Modal>
                </div>
                <div className={s.addressServices__productRight}>
                    <div className={s.addressServices__productPrices}>
                        <div className={s.addressServices__productPrice}>
                            <PriceWithoutVat price={price.value} />
                        </div>
                        <div className={s.addressServices__productPrice___discounted}>
                            <PriceWithoutVat price={discounted_price.value} postText="for 12 months" />
                        </div>
                    </div>
                    <div className={s.addressServices__productActions}>
                        {quantity_toggle ? (
                            <>
                                <input
                                    type="number"
                                    onChange={e => parseInt(e.target.value, 10) > 0 ? setQuantity(parseInt(e.target.value, 10)) : setQuantity(1)}
                                    className={s.addressServices__productQuantity}
                                    name={`quantity-box-${id}`}
                                    min="1"
                                    max="9"
                                    defaultValue={quantity}
                                    disabled={isInCart('id', id)}
                                />
                                { !isInCart('id', id) ? (
                                    <Button
                                        role="primary"
                                        className={s.addressServices__addButton}
                                        onClick={() => addToCart(id, quantity, type)}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        role="secondary"
                                        className={s.addressServices__removeButton}
                                        onClick={() => removeFromCart(id)}
                                    >
                                        <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faTimes')} />
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                {!isInCart('id', id) ? (
                                    <Button
                                        role="primary"
                                        className={s.addressServices__addButton}
                                        onClick={() => addToCart(id, 1, type)}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Button
                                        role="secondary"
                                        className={s.addressServices__removeButton}
                                        onClick={() => removeFromCart(id)}
                                    >
                                        <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faTimes')} />
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={s.addressServices}>
            <div className={`${s.pageTitle}`}>
                <div className={s.pageTitle__left}>
                    <div className={s.pageTitle__heading}>
                        <h1><Markdown source={node.page_title} /></h1>
                    </div>
                </div>
                <div className={s.pageTitle__right}>
                    <Button
                        role="primary"
                        onClick={() => proceedCheckout()}
                    >
                        No thanks, proceed to checkout
                    </Button>
                </div>
                <span className={s.pageTitle__divider} />
            </div>
            <div className={s.addressServices__content}>
                <PageSections slug="address-services" />
            </div>
            <div className={s.addressServices__services}>
                {addressServices.map(({ node }): JSX.Element => (
                    <div
                        key={`address-service-${node.slug}`}
                        data-service={node.slug}
                        className={`${s.addressServices__service}`}
                    >
                        <div className={s.addressServices__left}>
                            <img src={node.image.data.full_url} alt={`Outside of ${node.title}.`}/>
                        </div>
                        <div className={s.addressServices__right}>
                            <div className={s.addressServices__header}>
                                <h2 className={s.addressServices__title}>{node.title}</h2>
                            </div>
                            <div className={s.addressServices__body}>
                                <Markdown source={node.content} container />
                            </div>
                            <div className={s.addressServices__products}>
                                <Product location="London" type={node.slug} {...node.london} />
                                <Product location="Glasgow" type={node.slug} {...node.glasgow} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Button
                role="primary"
                className={s.addressServices__checkoutButton}
                onClick={() => proceedCheckout()}
            >
                Proceed to Checkout
            </Button>
        </div>
    );
};
