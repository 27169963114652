import { PackageEdges } from 'hooks/packages/packages.interface';

export const getCheckoutPath = (slug: string, packages: PackageEdges[]): string => {
    if (slug !== null) {
        return packages.filter(({ node }: PackageEdges) => node.slug === slug)
            .map(({ node }: PackageEdges) => node.checkout_path).join(', ');
    }

    return null;
};
